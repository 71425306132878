<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <span>
              <h5>Gestione FAQ</h5>
            </span>
          </CCardHeader>
          <CCardBody>
            <div v-for="faq in faqs" :key="faq.id">
              <CCardHeader>
                <h5>Domanda</h5>
                <CInput v-model="faq.domanda" />
                <h5>Risposta</h5>
                <textarea v-model="faq.risposta" rows="20" cols="100"></textarea>
                <br />
                <br />
                <UploadMultiplePhotoAndPreview
                  @getPhoto="
                    (photo) => {
                      faq.img = photo;
                    }
                  "
                  :aspectRatioVariableProps="aspectRatioVariableProps"
                  :img="faq.img"
                  :title="title"
                />
                <br />

                <CButton
                  v-on:click="update(faq)"
                  type="submit"
                  color="primary"
                  class="custom-button-save-faq"
                >
                  <CIcon name="cil-save" />
                  Salva
                </CButton>

                <CButton
                  v-on:click="destroy(faq)"
                  type="submit"
                  color="danger"
                  class="custom-button-destroy-faq"
                >
                  <CIcon name="cil-trash" />
                  Rimuovi
                </CButton>
              </CCardHeader>
              <br />
            </div>

            <CButton v-on:click="create()" type="submit" color="info">
              <CIcon name="cil-plus" />
              Aggiungi
            </CButton>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import UploadMultiplePhotoAndPreview from '@/views/funer24/components/UploadMultiplePhotoAndPreview';
import { VueEditor } from 'vue2-editor';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Faq',
  components: {
    UploadMultiplePhotoAndPreview,
    VueEditor,
  },
  data: function () {
    return {
      title: 'FAQ',
      aspectRatioVariableProps: 1.78,
    };
  },
  computed: {
    ...mapGetters('faq', ['faqs']),
  },
  methods: {
    ...mapActions('faq', ['list', 'create', 'destroy', 'update']),
  },
  created() {
    this.list();
  },
};
</script>

<style scoped>
button.btn.custom-button-destroy-faq.btn-danger {
  margin-left: 12px;
}

.btn.button-right-save-faq.btn-success.btn-lg {
  margin-left: 14px;
}

.custom-button-destroy-faq.btn-danger.btn-lg {
  margin-left: 12px;
}
</style>
